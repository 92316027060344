import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Box, Flex, Text } from '@chakra-ui/react';
import { HistoryTab, LinkTab, ScrollRightContainer, AddNotesModal } from 'components';
import {
  UserInformation,
  UserDeposit,
  UserTransactions,
  UserWithdrawal,
  UserCoinWallet,
  UserCard,
  UserLien,
} from 'pages';
import { useLocationSearch } from 'hooks';
import { UserLinkArray } from 'LinkArray';
import { fetchBanHistory, fetchDocumentByUserId, fetchSingleUser } from 'store/slices/user.slice';
import { getHeaders } from 'utils';
import { fetchUserWallet } from 'store/slices/wallet.slice';
import UserDetailsScrollContainer from 'components/User/UserDetailsScrollContainer';

const UserDetails = () => {
  const dispatch = useDispatch();
  const search = useLocationSearch();

  const { id } = useParams();

  const {
    allUsers: {
      singleUser: { data, status },
      reviewDocuments,
    },
    wallet: { singleUserWallet },
  } = useSelector((state) => state);

  const headers = getHeaders();

  useEffect(() => {
    dispatch(fetchSingleUser(id));
    dispatch(fetchDocumentByUserId(id));
    dispatch(fetchBanHistory(id));
    dispatch(fetchUserWallet(id));
  }, [dispatch, id]);

  return (
    <ScrollRightContainer maxWidth={{ base: '700px', tb: '950px', lg: '1121px' }}>
      <Flex
        justifyContent='space-between'
        alignItems='center'
      >
        <HistoryTab
          firstText='All Users'
          secondText='Users'
          url='/dashboard/users'
        />

        {data?.fullName && (
          <AddNotesModal
            userId={id}
            fullName={data?.fullName}
          />
        )}
      </Flex>

      <Box
        padding={{ base: '32px 0px', tb: '50px 32px' }}
        bg='#FCFDFD'
        boxShadow='0px 2px 8px rgba(229, 229, 229, 0.47)'
        borderRadius='10px'
        maxWidth={{ base: 'initial', md: '950px', lg: 'initial' }}
        width='100%'
      >
        <Flex
          alignItems={{ sm: 'flex-start', md: 'center' }}
          gridGap={{ sm: '24px', md: '131px' }}
          justifyContent={{ base: 'flex-start', md: 'space-between' }}
          mb='38px'
          flexDir={{ sm: 'column', md: 'row' }}
        >
          <Flex
            alignItems='center'
            gridGap={{ sm: '12px', md: '20px' }}
            width='100%'
          >
            <Box
              minW={{ base: 'initial', md: '208px' }}
              width={{ base: 'initial', md: '100%' }}
            >
              <Text
                fontWeight='600'
                fontSize='18px'
                lineHeight='24px'
                textTransform='capitalize'
              >
                {data?.fullName}
              </Text>
            </Box>

            <Box
              padding='11px'
              bg='rgba(111, 190, 69, 0.2)'
              borderRadius='5px'
              minWidth='102px'
            >
              <Text
                lineHeight='16px'
                color='#0D4740'
                fontSize='12px'
                fontWeight='500'
              >
                Status:&nbsp; {data?.active ? 'Active' : 'Not Active'}
              </Text>
            </Box>
          </Flex>

          <UserDetailsScrollContainer
            data={data}
            dispatch={dispatch}
            headers={headers}
            id={id}
            reviewDocuments={reviewDocuments}
          />
        </Flex>

        <ScrollRightContainer hideScrollbar>
          <LinkTab
            linkArray={UserLinkArray}
            gridGap='0'
          />
        </ScrollRightContainer>

        <Box
          mt='3rem'
          padding='0 1rem 1rem'
        >
          {search === '' && (
            <UserInformation
              data={data}
              status={status}
            />
          )}

          {search === '?withdrawal' && <UserWithdrawal userId={id} />}

          {(search === '?deposit' || search === '?deposit=crypto' || search === '?deposit=fiat') && (
            <UserDeposit userId={id} />
          )}

          {search === '?wallet' && <UserCoinWallet wallet={singleUserWallet} />}

          {search === '?transactions' && <UserTransactions userId={id} />}

          {search === '?cards' && <UserCard userId={id} />}

          {search === '?lien' && <UserLien userId={id} />}
        </Box>
      </Box>
    </ScrollRightContainer>
  );
};

export default UserDetails;
